<template>
  <div class="header flex space-between alignCenter" :class="{headerbk: toubu == true}">
    <img
      src="@/assets/img/logo/logoTop2.png"
      style="height: 50%;cursor:pointer;"
      @click="backToFirstPage"
    />
    <div class="tabs">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">
          <el-dropdown trigger="click" placement="bottom-start">
            <span class="el-dropdown-link">
              Products
            </span>
            <el-dropdown-menu slot="dropdown" class="proxiala">
              <div class="left_pro">
                <p class="xialahead">Hardware Products</p>
                <div class="left_content">
                  <p 
                    v-for="(item,index) in leftdata" 
                    :key="index"
                    :class="{active: index === currentIndex}"
                    @click='leftClick(index)'
                  >{{item}}</p>
                </div>
              </div>
              <div class="right_pro">
                <p class="xialahead">Software Products</p>
                <div class="left_content">
                  <p 
                    v-for="(item,index) in rightdata" 
                    :key="index"
                    :class="{actives: index === currentIndex1}"
                    @click='rightClick(index)'
                  >{{item}}</p>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <el-menu-item index="2">
          <el-dropdown trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              Solutions
            </span>
            <el-dropdown-menu slot="dropdown" class="solxiala">
              <p 
                v-for="(item,index) in coludata" 
                :key="index"
                :class="{active: index === solindex}"
                @click='solClick(index)'
              >
                {{item}}
              </p>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <el-menu-item index="3" @click="jumpdemo">Request Demo</el-menu-item>
        <el-menu-item index="4">About Us</el-menu-item>
      </el-menu>
      <div class="header_right">
        <p style="cursor:pointer;" @click="jumpsign">SIGN IN</p>
        <el-button @click="addfree">Free Trial</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:'headers',
  data(){
    return {
      currentIndex: 3,
      currentIndex1: 3,
      solindex:3,
      aboutus: 0,
      activeIndex: '1',
      activeName: 'first',
      toubu:false,
      leftdata:[
        'Intelligent Video Sensing terminal',
        'Intelligent Edge Computing Gateway',
        'Video Traffic Incident Detector'
      ],
      rightdata:[
        'Intelligent Video Surveillance Platform',
        'IoT Platform',
        'IoV Platform'
      ],
      coludata:[
        'Site Safety Early Warning Solution',
        'Traffic Condition Solution',
        'Intelligent Patrol Solution'
      ]
    }
  },
  created(){
    this.toubu = window.sessionStorage.getItem('headerback')
    console.log(this.toubu)
  },
  mounted(){
    // switch(window.location.pathname){
    //   case '/Aboutus' : 
    // }
    console.log(this.activeIndex)
  },
  methods:{
    backToFirstPage() {
      this.$router.push("/firstPage");
    },
    addfree(){
      this.$router.push('/FreeTrial')
    },
    jumpdemo(){
      // window.location.href = 'http://www.chasingai.com'
      window.open("http://www.chasingai.com")
    },
    jumpsign(){
      window.open('https://www.chasingai.com:8003/login')
    },
    leftClick(index){
      this.currentIndex = index;
      this.currentIndex1 = 3
    },
    rightClick(index){
      this.currentIndex1 = index;
      this.currentIndex = 3
    },
    solClick(index){
      this.solindex = index; 
    },
    handleSelect(key){
      console.log(key)
    }
  }
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 100px;
  padding: 0 117px;
  position: fixed;
  top: 0;
  z-index: 999;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  .tabs{
    width:100%;
    height:60px !important;
    margin-left:50px;
  }

  .tabs .el-menu{
    background:#fff;
    width:65%;
    border-bottom:0;
    float: left;
  }

  .tabs .el-menu .el-menu-item{
    width:24%;
    border-bottom:0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    color:#000;
  }

  .tabs .el-menu .el-menu-item:hover{
    background:#fff;
    color:#0D8AF2;
  }

  .tabs .el-menu .is-active{
    background:#fff;
  }

  .header_right{
    width:31%;
    height:60px;
    background:#fff;
    float: left;
    // border-left:1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .header_right p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    color:#000;
  }

  .header_right p:hover{
    color:#0D8AF2;
  }

  .header_right .el-button,
  .left_text .el-button{
    width:170px;
    height:40px;
    padding:0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    background: #FFA000;
    color:#fff;
    border-radius: 10px;
    border:0;
  }
}

.header_right .el-button:hover{
  background:#FF8A00;
}

.proxiala{
  width:600px;
  border-radius: 12px;
  .left_pro{
    width:50%;
    height:250px;
    float:left;
    padding-left:18px;
    padding-right:35px;
    .xialahead{
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      align-items: center;
      color: #000000;
      height:10%;
      border-bottom:2px solid #dedede;
      padding-bottom:5px;
    }
    .left_content{
      height:90%;
      padding-top:20px;
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        color: #000000;
        margin-bottom:16px;
        cursor: pointer;
      }
    }
  }
  .right_pro{
    width:50%;
    height:250px;
    float:left;
    padding-left:18px;
    padding-right:35px;
    .xialahead{
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      align-items: center;
      color: #000000;
      height:10%;
      border-bottom:2px solid #dedede;
      padding-bottom:5px;
    }
    .left_content{
      height:90%;
      padding-top:20px;
      p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        color: #000000;
        margin-bottom:16px;
        cursor: pointer;
      }
    }
  }
}

.el-dropdown{
  border-bottom:0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  text-align: center;
  color:#000;
}

.el-dropdown span:hover{
  color:#0D8AF2;
}

.solxiala{
  width:255px;
  border-radius: 12px;
  padding-left:32px;
  padding-top:20px;
  p{
    width:230px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    color: #000000;
    cursor: pointer;
    margin-bottom:30px;
  }
}

.active{
  color:#0D8AF2 !important;
}
.actives{
  color:#0D8AF2 !important;
}

.headerbk{
  background: red !important;
}

</style>